import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29deae45"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "el-radio-group" }
const _hoisted_2 = ["value", "name", "disabled"]
const _hoisted_3 = { class: "el-radio-button__inner" }
const _hoisted_4 = {
  key: 0,
  class: "item__error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (x, index) => {
        return (_openBlock(), _createElementBlock("label", {
          key: index,
          class: _normalizeClass(["el-radio-button", [
        _ctx.border && _ctx.size ? 'el-radio-button--' + _ctx.size : '',
        { 'is-disabled': x.disabled },
        { 'is-focus': x.focus },
        { 'is-bordered': _ctx.border },
        { 'is-active': _ctx.model === x.value }
      ]]),
          role: "radio"
        }, [
          _withDirectives(_createElementVNode("input", {
            ref_for: true,
            ref: "radio",
            class: "el-radio-button__orig-radio",
            value: x.value,
            type: "radio",
            "aria-hidden": "true",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
            name: _ctx.name,
            disabled: x.disabled,
            tabindex: "-1"
          }, null, 8, _hoisted_2), [
            [_vModelRadio, _ctx.model]
          ]),
          _createElementVNode("span", _hoisted_3, _toDisplayString(x.label), 1)
        ], 2))
      }), 128))
    ]),
    (_ctx.errors.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.errors[0]), 1))
      : _createCommentVNode("", true)
  ], 64))
}